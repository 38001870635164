<section id="cta" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000" class="cta aos-init pb-5">
  <div class="container mb-5">
      <div class="row mt-5">
          <div class="col-lg-12">
            <h2>Aletaanko rakentamaan uutta?</h2>
            <p class="text-muted">Jos mielenkiintosi heräsi, niin jätä sähköpostisi allaolevaan kenttään tai laita postia
              osoitteeseen info@mediapilvi.fi
            </p>
          </div>
        </div>
        <app-mail-form></app-mail-form>
  </div>

</section>


<footer class="pt-5 mt-5 site-footer">
  <div class="container mt-4">
    <div class="row">
      <div class="col-md-3 col-sm-12 mb-3 p-2">
          <h4 class="mb-2 text-uppercase">
              Mediapilvi
          </h4>
      </div>
      <div class="col-md-3 col-sm-12 mb-3 p-2">
          <h4 class="mb-2">Osoite</h4>
          <p class="mb-0">Mediapilvi</p>
          <p class="mb-0">Fleminginkatu 12a A 34</p>
          <p class="mb-0">00530, Helsinki</p>
          <p class="mb-0">Y-tunnus: 2478747-7</p>
      </div>
      <div class="col-md-3 col-sm-12 mb-3 p-2">
        <h4 class="mb-2">Ota yhteyttä</h4>
        <p class="mb-0">info@mediapilvi.fi</p>
        <p>+358 447 245 577</p>
      </div>
      <div class="col-md-3 col-sm-12 mb-3 p-2">
        <h4 class="mb-2">Käyttöehdot</h4>
        <p>Käyttämällä sivustoa hyväksyt <a routerLink="/ehdot" routerLinkActive="active"  >käyttöehtomme</a></p>
      </div>
    </div>
  </div>
</footer>

