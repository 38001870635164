import { Component, OnInit } from "@angular/core";
import VanillaTilt from "vanilla-tilt";
import { MetaService } from "../../services/meta.service";
import {
  ScrollToService,
  ScrollToConfigOptions,
} from "@nicky-lenaers/ngx-scroll-to";
import { DbService } from "src/app/services/db.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  highlightCase: any;
  highlightBlog: any;
  highlightBlog$;
  highlightCase$;
  loaded = false;
  public siteTitle = "Verkkopalveluita oikeisiin tarpeisiin";
  public metaTitle = "Mediapilvi.fi - Verkkopalveluita oikeisiin tarpeisiin";
  public metaDesc =
    "Mediapilvi on Helsingissä sijaitseva digitoimisto. Haluamme auttaa ihmisiä ja yrityksiä ymmärtämään ja ottamaan innokkaasti vastaan digitaalinen maailma.";
  public metaUrl = "https://mediapilvi.fi";

  constructor(
    private meta: MetaService,
    private scrollToService: ScrollToService,
    private db: DbService
  ) {
    this.meta.updateTitle(this.metaTitle);
    this.meta.updateMetaInfo(
      this.metaTitle,
      this.metaDesc,
      "https://mediapilvi.fi/assets/icon/mediapilvi-og-image.png"
    );

    this.highlightCase$ = this.db.collection$("fl_content", (ref) =>
      ref
        .where("_fl_meta_.env", "==", "production")
        .where("_fl_meta_.locale", "==", "en-US")
        .where("_fl_meta_.schema", "==", "cases")
        .where("published", "==", "1")
        .where("highlighted", "==", "1")
        .limit(1)
    );
    this.highlightCase$.subscribe((highlightcase) => {
      this.highlightCase = highlightcase[0];
    });

    this.highlightBlog$ = this.db.collection$("fl_content", (ref) =>
      ref
        .where("_fl_meta_.env", "==", "production")
        .where("_fl_meta_.locale", "==", "en-US")
        .where("_fl_meta_.schema", "==", "blogs")
        .where("published", "==", "1")
        .where("highlighted", "==", "1")
        .limit(1)
    );
    this.highlightBlog$.subscribe((highlightblog) => {
      this.highlightBlog = highlightblog[0];
    });
  }

  ngOnInit() {
    this.loaded = true;
    const cards = document.querySelectorAll(".card-container");
    VanillaTilt.init(cards as any, {
      reverse: true,
      max: 15, // max tilt rotation (degrees)
      perspective: 2000, // Transform perspective, the lower the more extreme the tilt gets.
      scale: 1.05, // 2 = 200%, 1.5 = 150%, etc..
      speed: 500, // Speed of the enter/exit transition
      transition: true, // Set a transition on enter/exit.
    });
  }

  scrollToElement($element): void {
    $element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  public triggerScrollTo() {
    const config: ScrollToConfigOptions = {
      target: "mediapilvi",
      offset: -150,
    };

    this.scrollToService.scrollTo(config);
  }
}
