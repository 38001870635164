import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-aos-img-bg',
  templateUrl: './aos-img-bg.component.html',
  styleUrls: ['./aos-img-bg.component.scss']
})
export class AosImgBgComponent implements OnInit {

  @Input() imgSrc: string;
  @Input() color: string;

  constructor() { }

  ngOnInit(): void {
  }

}
