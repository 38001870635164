import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MetaService {
    constructor(
        private titleService: Title,
        private meta: Meta,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) { }

    updateMetaInfo(title, desc, image) {
        this.meta.updateTag({ name: 'description', content: desc });
        this.meta.updateTag({ name: 'og:title', content: title });
        this.meta.updateTag({ name: 'og:url', content: `https://mediapilvi.fi${this.router.url}` });
        this.meta.updateTag({ name: 'og:description', content: desc });
        this.meta.updateTag({ name: 'twitter:title', content: title });
        this.meta.updateTag({ name: 'twitter:description', content: desc });
        this.meta.updateTag({ name: 'og:image', content: image }),
        this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
        this.meta.updateTag({ name: 'twitter:site', content: '@mediapilvi' });
    }

    updateTitle(title?: string) {
        if (!title) {
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    map(() => this.activatedRoute),
                    map((route) => {
                        while (route.firstChild) { route = route.firstChild; }
                        return route;
                    }),
                    filter((route) => route.outlet === 'primary'),
                    mergeMap((route) => route.data)).subscribe((event) => {
                        this.titleService.setTitle(event.title + ' | Mediapilvi');
                    });
        } else {
            this.titleService.setTitle(title + ' | Mediapilvi');
        }
    }

}
