import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetaService } from '../../services/meta.service';
import { DbService } from 'src/app/services/db.service';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
@Component({
  selector: 'app-case-detail',
  templateUrl: './case-detail.component.html',
  styleUrls: ['./case-detail.component.scss']
})
export class CaseDetailComponent implements OnInit {
  public case: any;
  case$;
  public id: any;
  public metaUrl = 'https://mediapilvi.fi';

  public img = 'assets/test.png';


  constructor(private route: ActivatedRoute, private meta: MetaService, private db: DbService, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    const slug: string = this.route.snapshot.paramMap.get('slug');
    this.case$ = this.db.collection$('fl_content', ref =>
    ref
      .where('_fl_meta_.env', '==', 'production')
      .where('_fl_meta_.locale', '==', 'en-US')
      .where('_fl_meta_.schema', '==', 'cases')
      .where('slug', '==', slug)
      .where('published', '==', '1')
    );

    this.case$.subscribe(c => {
      this.case = c[0];
      this.case.body = this.sanitizer.bypassSecurityTrustHtml(this.case.body);
      this.meta.updateTitle(this.case.title);
      this.meta.updateMetaInfo(this.case.title, this.case.summary, this.case.coverImage);

    });

  }

}
