<div class="row">
  <div class="col-md-6 offset-md-3 mx-auto">
    <form class="form" [formGroup]="emailForm" id="emailForm" (ngSubmit)="submit($event)">
        <label class="sr-only" for="name">nimesi</label>
        <input formControlName="name" type="text" class="form-control mb-2" id="name" placeholder="nimesi">  
      <label class="sr-only" for="email">sähköpostisi</label>
      <input formControlName="email" type="email" class="form-control mb-2" id="email" placeholder="sinun@sähköposti">
      <label class="sr-only" for="desc">kuvaus</label>
      <textarea rows="3" maxlength="255" formControlName="desc" type="text" class="form-control mb-2" id="desc" placeholder="Kerro ihan lyhyesti tarpeestasi. Max 255 merkkiä."></textarea>
      <button [disabled]="emailForm.invalid || sending" class="btn btn-block btn-mp" type="submit">Lähetä</button>
    </form>
  </div>
</div>
