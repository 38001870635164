import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Component({
  selector: 'app-mail-form',
  templateUrl: './mail-form.component.html',
  styleUrls: ['./mail-form.component.scss']
})
export class MailFormComponent implements OnInit {
  emailForm: FormGroup;

  sending = false;

  endpoint = 'https://us-central1-mediapilvi-fi.cloudfunctions.net/httpEmail';
  error = '';

  constructor(private http: HttpClient, private fb: FormBuilder, private toastr: ToastrService) {
  }

  ngOnInit() {
    this.emailForm = this.fb.group({
      email: ['', [
        Validators.email,
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(250)
      ]],
      name: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ]],
      desc: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(255)
      ]]
    });
  }

  click() {
    this.toastr.success('');
  }

  submit(e) {
    this.sending = true;
    e.preventDefault();

    const data = {
      email: this.emailForm.value.email,
      name: this.emailForm.value.name,
      desc: this.emailForm.value.desc,
    };


    this.http.post(this.endpoint, data).subscribe(
      (res: Response) => {
        this.emailForm.reset();
        this.sending = false;
        this.toastr.success('Kiitos, palataan pian asiaan! 😊');
      }, error => {
        this.sending = false;
        console.log(error);
        this.toastr.toastrConfig.enableHtml = true;
        this.toastr.error('Jotain pielessä 😳 Virhekoodi: ' + this.error);
    });

  }

}
