import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetaService } from '../../services/meta.service';
import { DbService } from 'src/app/services/db.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailComponent implements OnInit {
  blog$;
  public blogPost: any;
  public id: any;
  public metaUrl = 'https://mediapilvi.fi';

  constructor(
    private route: ActivatedRoute,
    private meta: MetaService,
    private db: DbService,
    private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    const slug: string = this.route.snapshot.paramMap.get('slug');
    this.blog$ = this.db.collection$('fl_content', ref =>
    ref
      .where('_fl_meta_.env', '==', 'production')
      .where('_fl_meta_.locale', '==', 'en-US')
      .where('_fl_meta_.schema', '==', 'blogs')
      .where('slug', '==', slug)
      .where('published', '==', '1')
    );

    this.blog$.subscribe(blog => {
      this.blogPost = blog[0];
      this.blogPost.body = this.sanitizer.bypassSecurityTrustHtml(this.blogPost.body);
      this.meta.updateTitle(this.blogPost.title);
      this.meta.updateMetaInfo(this.blogPost.title, this.blogPost.summary, this.blogPost.coverImage);
    });

  }

}
