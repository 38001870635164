<div class="container pt-5 mb-5 pb-5">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
  
        <section id="page-section" *ngIf="case">
  
        <div class="page-content pt-lg-5">
            <article>
            <h1 class="my-2 ">{{case?.title}}</h1>
            <h2 class="my-2">{{case?.summary}}</h2>
              <div class="my-4" [innerHTML]="case?.body"></div>

            </article>
          </div>
        </section>
  
      </div>
    </div>
  </div>