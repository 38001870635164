<div class="container pt-3 mb-5 pb-5">
  <div class="row">
    <div class="col-lg-8 offset-lg-2">

      <section id="page-section" *ngIf="blogPost">
        <div class="page-content pt-lg-5">
          <article>
            <img class="img-fluid" [src]="blogPost?.coverImage">
            <h1 class="my-2 ">{{blogPost?.title}}</h1>
            <h2 class="my-2 ">{{blogPost?.summary}}</h2>
            <p class="my-4 blog-author"><img [src]="blogPost?.authorImage" [alt]="blogPost?.author" class="avatar mr-2">{{blogPost?.author}} <span class="post-created">({{blogPost?.date | date: 'dd/MM/yyyy' }})</span></p>
            <div [innerHTML]="blogPost?.body"></div>
          </article>
        </div>
        <a routerLink="/blogi" class="btn btn-sm blog-back-button">< Takaisin blogiin</a>
      </section>

    </div>
  </div>
</div>