import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailComponent } from './blog-detail/blog-detail.component';
import { CasesComponent } from './cases/cases.component';
import { CaseDetailComponent } from './case-detail/case-detail.component';
import { ContactComponent } from './contact/contact.component';
import { TermsComponent } from './terms/terms.component';
import { JumbotronComponent } from '../components/jumbotron/jumbotron.component';
import { RouterModule } from '@angular/router';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { AosImgBgComponent } from '../components/aos-img-bg/aos-img-bg.component';

@NgModule({
  declarations: [
    HomeComponent,
    AboutComponent,
    BlogComponent,
    BlogDetailComponent,
    CasesComponent,
    CaseDetailComponent,
    ContactComponent,
    TermsComponent,
    JumbotronComponent,
    AosImgBgComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    HttpClientJsonpModule
  ]
})
export class PagesModule { }
