<div class="container">
  <div class="row">
    <div class="col-md-12">

      <section id="page-section">

        <div class="page-header">
          <h1>Käyttöehdot</h1>
        </div>

        <div class="page-content">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <p><strong>Yleistä</strong></p>
              <p>Käyttöehdot koskevat tätä Mediapilven (myös "palveluntarjoaja") verkkosivua mediapilvi.fi (myös
                "palvelu"). Palvelun omistaa Mediapilvi.</p>
              <p>Palvelun käyttäminen edellyttää käyttöehtojen hyväksymistä.</p>
              <p><strong>Käyttäjän vastuu</strong></p>
              <p>Käyttäjä sitoutuu käyttämään palvelua sen ohjeiden mukaisesti.</p>
              <p>Käyttäjä vastaa palveluntarjoajan ja sen yhteistyökumppaneiden tekijänoikeuksilla suojatun materiaalin
                luvattomasta käyttämisestä aiheutuvista seuraamuksista.</p>
              <p><strong>Palveluntarjoajan vastuu</strong></p>
              <p>Palveluntarjoaja vastaa kaikesta palvelussa esitettävästä sisällöstä.</p>
              <p>Palveluntarjoaja vastaa, että kaikki palvelussa esitettävä sisältö (kuvat yms.) on Suomen lakien
                mukaista.</p>
              <p>Palvelutarjoajalla on oikeus muuttaa ja päivittää ja korjata palvelun sisältöä ja/tai sääntöjä ilman
                erillistä ilmoitusta käyttäjille.</p>
              <p>Palveluntarjoaja ei vastaa ulkopuolisten palveluiden tai yritysten esittämästä virheellisestä palvelua
                koskevasta informaatiosta.</p>
              <p>Palveluntarjoaja vastaa sille kertyvistä kaikista asiakastiedoista ja niiden asianmukaisesta
                säilyttämisestä.</p>
              <p>Väärinkäytöksistä ilmoitetaan aina viranomaisille.</p>
              <p><strong>Palvelun immateriaalioikeudet</strong></p>
              <p>Kaikki palvelussa esitetyt kuva- ja tekstimateriaalit ovat palveluntarjoajan ja/tai sen
                yhteistyökumppaneiden omaisuutta. Tekijänoikeuksilla suojatun kuva- ja tekstimateriaalin luvaton käyttö
                tai kopiointi on kielletty.</p>
              <p><strong>Evästeiden käyttö</strong></p>
              <p>Palvelussa käytetään cookie-toimintoa eli evästeitä. Evästeet eivät vahingoita käyttäjien tietokoneita
                tai tiedostoja. Evästeitä käytetään, koska niiden avulla voimme tarjota asiakkaille yksilöityjen
                tarpeiden mukaisia tietoja ja palveluita. Evästeen poiskytkeminen selaimesta saattaa vaikuttaa
                palveluiden asianmukaiseen toimintaan.</p>
              <p>Palvelu käyttää evästeitä (ns. cookie), joiden avulla seurataan kävijäliikennettä ja parannetaan
                palvelun laatua. Palveluntarjoajan palvelun julkisen käytön seuranta ja käytön tilastollinen
                analysointi toteutetaan Google Analytics –verkkoanalyysipalvelun avulla, jonka tarjoaa Google, Inc.
                (jäljempänä ”Google”). Google –Analytics palveluun kerätystä käyttötiedosta palveluntarjoaja tilaa
                palveluiden käyttötilastoja, joissa yksittäistä käyttäjää ei tunnisteta. Käyttötilastoja hyödynnetään
                palvelun seurannan, sisällöllisen kehittämisen, markkinoinnin ja palvelun ylläpidon tarpeisiin.
                Palveluntarjoaja ei luovuta käyttötietoja tai tilastoja suoramarkkinointiin tai muutoin ulkopuolisille.
                Tietoa Google Analytics-palvelusta: <a href="http://www.google.com/analytics/">http://www.google.com/analytics/</a></p>
              <p>Käyttämällä tätä sivustoa annat suostumuksesi siihen, että Google käsittelee sinua koskevia tietoja
                edellä mainitulla tavalla ja edellä mainittuja tarkoituksia varten.</p>
            </div>
          </div>

        </div>

      </section>

    </div>
  </div>
</div>