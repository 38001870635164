<nav class="navbar navbar-light fixed-top navbar-expand-md" id="mainNav">

  <div class="container">
    <a class="navbar-brand" rel="home" routerLink="/" title="Mediapilvi" itemprop="url">Mediapilvi</a>

    <button type="button" class="btn d-md-none navbar-open-button btn-lg" data-toggle="modal" data-target="#mpNavModal"></button>

    <ul class="nav navbar-nav ml-auto d-none d-md-flex">
      <li class="nav-item">
        <a class="nav-link" routerLink="/tietoa" routerLinkActive="active" class="nav-link">Tietoa</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/caset" routerLinkActive="active" class="nav-link">Caset</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/blogi" routerLinkActive="active" class="nav-link">Blogi</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/otayhteytta" routerLinkActive="active" class="nav-link">Ota yhteyttä</a>
      </li>
    </ul>
  </div><!-- .container -->

</nav><!-- .site-navigation -->

<div class="modal right fade modal-full" id="mpNavModal" tabindex="-1" role="dialog" aria-labelledby="mpNavModal"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-body text-right">
          <div class="modal-header">
              <a class="navbar-brand" rel="home" (click)="closeNav()" routerLink="/" title="Mediapilvi" itemprop="url"></a>
              <button type="button" class="btn my-auto d-md-none navbar-close-button btn-lg" data-dismiss="modal" aria-label="Close">
              </button>
            </div>
        <ul class="nav navbar-nav">
          <li class="nav-item">
            <a class="nav-link" (click)="closeNav()" routerLink="/tietoa" routerLinkActive="active" class="nav-link">Tietoa</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="closeNav()" routerLink="/caset" routerLinkActive="active" class="nav-link">Caset</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="closeNav()" routerLink="/blogi" routerLinkActive="active" class="nav-link">Blogi</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="closeNav()" routerLink="/otayhteytta" routerLinkActive="active" class="nav-link">Ota
              yhteyttä</a>
          </li>
        </ul>
      </div>
      <div class="modal-box modal-box-1 d-flex">
          <div class="heavy-highlight m-auto"><a class="m-auto" href="mailto:info@mediapilvi.fi?Subject=Moi%20Mediapilvi" target="_top">info@mediapilvi.fi</a></div>
      </div>
      <div class="modal-box mb-5 modal-box-2 d-flex">
          <div class="heavy-highlight m-auto"><a href="tel:+358447245577">+358447245577</a></div>
      </div>
    </div>
  </div>
</div>