import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailComponent } from './pages/blog-detail/blog-detail.component';
import { CasesComponent } from './pages/cases/cases.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CaseDetailComponent } from './pages/case-detail/case-detail.component';
import { TermsComponent } from './pages/terms/terms.component';

const routes: Routes = [
{
    path: '',
    pathMatch: 'full',
    component: HomeComponent
  }, {
    path: 'tietoa',
    component: AboutComponent
  }, {
    path: 'blogi',
    component: BlogComponent
  },
  {
    path: 'blogi/:slug',
    component: BlogDetailComponent
  }, {
    path: 'caset',
    component: CasesComponent
  }, {
    path: 'caset/:slug',
    component: CaseDetailComponent
  }, {
    path: 'otayhteytta',
    component: ContactComponent
  }, {
    path: 'ehdot',
    component: TermsComponent
  }, {
    path: 'caset/100/design-sprint-ja-mvp-produalille', redirectTo: 'caset/design-sprint-ja-mvp-produalille'
  }, {
    path: 'caset/27/lähiliikunta.fi---avoimen-datan-hyödyntäminen', redirectTo: 'caset/lähiliikunta.fi---avoimen-datan-hyödyntäminen'
  }, {
    path: 'blogi/101/hyvä-paha-palautesilmukka', redirectTo: 'blogi/hyva-paha-palautesilmukka'
  }, {
    path: 'blogi/98/kun-hyvä-ei-enää-riitä', redirectTo: 'blogi/kun-hyva-ei-enaa-riita'
  }, {
    path: '**', redirectTo: ''
  }



];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
