<div class="container pt-5 mb-5 pb-5">
  <div class="row">
    <div class="col-md-12">

      <section id="page-section">

        <div class="page-header">
          <h1><span class="text-muted d-block">Tarinoita ja ajatuksia </span> <span class="highlighting"> pilvien</span>
            päältä</h1>
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <p>Mediapilven blogissa leijutaan designin ja tekniikan välillä, mutta yleisesti ottaen kaikissa jutuissa
                keskitytään siihen tärkeimpään eli käyttäjään. Jos löysit jonkin itseäsi kiinnostavan jutun niin ota
                yhteyttä ja jutellaan lisää.</p>
            </div>
          </div>
        </div>

        <div class="page-content" *ngIf="blogList">
          <div class="row p-1 m-1 p-md-3 m-md-3 pt-md-5 mp-highlight">
            <div class="col-lg-4" *ngFor="let blog of blogList; let even = even; let odd = odd" [ngClass]="{ odd: odd, even: even }"
              data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000">
              <a routerLink="/blogi/{{blog?.slug}}" class="card-container mb-3 mt-3">
                <div class="card" [ngStyle]="{'background-image': 'url(' + blog?.coverImage + ')'}" style="background-size: cover;">
                  <div class="card-body">
                    <p>{{blog?.date | date: 'dd/MM/yyyy' }}</p>
                    <div class="card-title">
                      {{ blog?.title }}
                    </div>
                    <p class="card-author"><img [src]="blog?.authorImage" [alt]="blog?.author" class="avatar mr-2">{{blog?.author}}</p>
                  </div>
                </div>
              </a>
            </div>
<!--             <div class="col-lg-4"
              data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000">
              <div class="card-container mb-3 mt-3">
                <div class="card" style="background: url('assets/img/coming-soon.png'); background-size: cover">
                  <div class="card-body">
                    
                    <div class="card-title">
                      Lisää tulossa heti joulun jälkeen 🎅
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </section>

    </div>
  </div>
</div>