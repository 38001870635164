import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  private renderer: Renderer2;
  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  openModal(htmlCode: string): HTMLElement {
    const modalOverlay = this.renderer.createElement("div");
    this.renderer.addClass(modalOverlay, "custom-modal-overlay");
    this.renderer.appendChild(document.body, modalOverlay);

    const modal = this.renderer.createElement("div");
    this.renderer.addClass(modal, "custom-modal");
    modal.innerHTML = htmlCode;
    this.renderer.appendChild(modalOverlay, modal);

    // Create a close icon element
    const closeIcon = this.renderer.createElement("span");
    this.renderer.addClass(closeIcon, "custom-modal-close-icon");
    closeIcon.innerHTML = "&times;";
    this.renderer.appendChild(modal, closeIcon);

    // Add a click event listener to the close icon
    closeIcon.addEventListener("click", () => {
      if (modalOverlay && modalOverlay.parentNode) {
        modalOverlay.parentNode.removeChild(modalOverlay);
      }
    });

    // Return the modal overlay element so that it can be used to add event listeners.
    return modalOverlay;
  }
}
