import { Component, OnInit } from '@angular/core';
import { MetaService } from '../../services/meta.service';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  public blogList: any;
  blogs$;
  blogs;

  public siteTitle = 'Blogi';
  public metaTitle = 'Blogi | Mediapilvi.fi';
  public metaDesc = 'Mediapilven blogissa leijutaan designin ja tekniikan välillä, mutta yleisesti ottaen kaikissa jutuissa keskitytään siihen tärkeimpään eli käyttäjään.';
  public metaUrl = 'https://mediapilvi.fi/blogi';

  constructor(
    private meta: MetaService,
    private db: DbService) {
      this.meta.updateTitle(this.siteTitle);
      this.meta.updateMetaInfo(this.metaTitle, this.metaDesc, 'https://mediapilvi.fi/assets/icon/mediapilvi-og-image.png');

    }



  ngOnInit() {

    this.blogs$ = this.db.collection$('fl_content', ref =>
      ref
        .where('_fl_meta_.env', '==', 'production')
        .where('_fl_meta_.locale', '==', 'en-US')
        .where('_fl_meta_.schema', '==', 'blogs')
        .where('published', '==', '1')
        .orderBy('date', 'desc')
        .limit(25)
      );
    this.blogs$.subscribe(blogs => {
      this.blogList = blogs;
    });

  }

}
