<div class="container pt-5 mb-5 pb-5">
  <div class="row">
    <div class="col-md-12">

      <section id="page-section">

        <div class="page-header">
          <h1><span class="text-muted d-block">Kun hyvä ratkaisu ei riitä </span> <span class="highlighting"> tarvitaan</span> oikea ratkaisu</h1>
        </div>
        <div class="page-content">
          <div class="row">
            <div class="col-md-8 offset-md-2">
                <p>Mediapilvi on Helsingissä sijaitseva digitoimisto. Suunnittelemme ja toteutamme verkkosivuja, verkkokauppoja ja verkkosovelluksia.</p>
                <p>Päätavoitteemme on löytää yksinkertaiset ratkaisut monimuotoisiin ongelmiin ja valmistaa niistä erinomaisia lopputuloksia. Yhdistämällä suunnittelun, teknologian ja luovuuden luomme kokonaisuuksia, jotka tukevat sekä sinua että asiakastasi.
                  </p>
                <p>
                  Haluamme auttaa ihmisiä sekä yrityksiä ymmärtämään digitaalista maailmaa ja ottamaan sen innokkaasti vastaan. Maailma muuttuu tällä hetkellä nopeaa vauhtia eikä onnistumista enää määritä se, että luomme hyvästä ideasta hyvän ratkaisun. Tarvitsemme myös oikeanlaisen ratkaisun.
                </p>

                  <p>
                    <a routerLink="/blogi/98/kun-hyvä-ei-enää-riitä">Käy lukemassa blogistamme, miten syntyy meidän mielestämme oikeanlainen tuote.</a>
                  </p>
            </div>
          </div>
        </div>

      </section>

    </div>
  </div>
</div>