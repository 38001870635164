import { Component, OnInit, HostListener , Inject} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  @HostListener('window:scroll', ['$event'])
  showNavbar(event): void {
    const nav = document.getElementById('mainNav');

    if (window.pageYOffset > 90) {
      nav.classList.add('navbar-shrink');
    } else {
      nav.classList.remove('navbar-shrink');
    }
  }

  closeNav() {
    const nav = document.getElementById('mpNavModal');
    nav.click();
  }

  ngOnInit() {
  }

}
