<div class="container pt-5 mb-5 pb-5">
  <div class="row">
    <div class="col-md-12">

      <section id="page-section">

        <div class="page-content">
            <div class="page-header">
                <h1><span class="text-muted d-block">Ota meihin </span> <span class="highlighting">yhteyttä</span></h1>
              </div>
          <div class="row">
            <div class="col-lg-8 offset-2">
              <p>Pistä viestiä tulemaan alapuolelta löytyvällä lomakkeella.</p>
              <p>Voit myös laittaa sähköpostia osoitteeseen <span class="heavy-highlight"><a href="mailto:info@mediapilvi.fi?Subject=Moi%20Mediapilvi" target="_top">info@mediapilvi.fi</a></span> tai soittaa <span class="heavy-highlight"><a href="tel:+358447245577">+358447245577</a></span></p>
            </div>
          </div>
        </div>

      </section>

    </div>
  </div>
</div>