import { Component, OnInit } from '@angular/core';
import VanillaTilt from 'vanilla-tilt';
import { MetaService } from '../../services/meta.service';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss']
})
export class CasesComponent implements OnInit {
  public caseList: any;
  public card: any;
  public siteTitle = 'Caset';
  public metaTitle = 'Caset | Mediapilvi.fi';
  public metaDesc = 'Helpoin tapa ymmärtää mitä teemme, on tutustua tekemiimme töihin. Katso alapuolelta case-esimerkkejä, aiemmin toteutuneista projektista.';
  public metaUrl = 'https://mediapilvi.fi/caset';

  cases$;
  cases;

  constructor(
    private meta: MetaService,
    private db: DbService) {
      this.meta.updateTitle(this.siteTitle);
      this.meta.updateMetaInfo(this.metaTitle, this.metaDesc, 'https://mediapilvi.fi/assets/icon/mediapilvi-og-image.png');

    }

  ngOnInit() {

    this.cases$ = this.db.collection$('fl_content', ref =>
      ref
        .where('_fl_meta_.env', '==', 'production')
        .where('_fl_meta_.locale', '==', 'en-US')
        .where('_fl_meta_.schema', '==', 'cases')
        .where('published', '==', '1')
        .orderBy('date', 'desc')
        .limit(25)
      );
    this.cases$.subscribe(cases => {
      this.caseList = cases;
    });

  }

}
