<header>

  <section id="jumbotron" class="jumbotron jumbotron-fluid d-flex align-items-center m-0 p-0">



    <div class="container" data-aos-duration="1000">
      <div *ngIf="!loaded" class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
      <app-jumbotron *ngIf="loaded"></app-jumbotron>
      <p class="mb-5 text-muted text-uppercase read-more">Rullaa alaspäin ja tutustu</p>
      <a (click)="triggerScrollTo()">
        <div class="arrow"></div>
      </a>
    </div>

  </section>

</header>

<section class="page-section">
  <div class="page-content">
    <div class="container">

      <section id="mediapilvi" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000" class="aos-init wrapper py-5 my-5">

        <div #target style="position: absolute; top: -150px; left: 0;"></div>
        <div class="row p-1 m-1 p-md-1 m-md-5 mp-highlight">
          <div class="col-lg-12 my-auto">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12">
                  <div class="feature-item">
                    <h3 class="mp-highlight-title">Moi! Tämä on <span class="heavy-highlight">Mediapilvi</span>.<br>Autamme
                      yrityksiä luomaan heidän visioistaan <span class="heavy-highlight">digitaalisia palveluita</span>, joilla on merkitys.</h3>
                    <a class="text-uppercase" routerLink="tietoa">Lue lisää</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

      <section id="highlight" data-aos="fade-in" data-aos-delay="50" data-aos-duration="1000" class="aos-init wrapper py-5 my-5">
        <div class="row p-1 m-1 p-md-3 m-md-3 mp-highlight">
          <div class="col-lg-12 p-3 p-lg-5">
            <h3 class="latest-news">Viimeisimmät uutiset</h3>
          </div>
          <div class="col-lg-6" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
            <a routerLink="/caset/{{highlightCase?.slug}}" routerLinkActive="active" class="card-container col-lg-12 mb-5 mt-5">
              <div class="card long-card" style="background-size: cover;" [ngStyle]="{'background-image': 'url(' + highlightCase?.coverImage + ')'}">
                <div class="card-body">
                  <p>Case</p>
                  <div class="card-title">
                    {{highlightCase?.title}}
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-lg-6">

            <a routerLink="/blogi/{{highlightBlog?.slug}}" routerLinkActive="active" class="card-container col-lg-12 mb-5" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
              <div class="card" style="background-size: cover" [ngStyle]="{'background-image': 'url(' + highlightBlog?.coverImage + ')'}">
                <div class="card-body">
                  <p>Blogi</p>
                  <div class="card-title card-title-blog">
                    {{highlightBlog?.title}}
                  </div>
                  <p class="card-author"><img [src]="highlightBlog?.authorImage" [alt]="highlightBlog?.author" class="avatar mr-2">{{highlightBlog?.author}}</p>
                </div>
              </div>
            </a>

            <a routerLink="/otayhteytta" routerLinkActive="active" class="card-container col-lg-12 mb-5" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
              <div class="card" style="background: url('assets/img/highlight-3.jpeg'); background-size: cover">
                <div class="card-body">
                  <div class="card-title">
                    Ota yhteyttä - Let's work
                  </div>
                </div>
              </div>
            </a>

          </div>
        </div>
      </section>

      <section id="about" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000" class="aos-init wrapper py-5 my-5">

        <div class="row pt-1 mt-1 pt-md-3 mt-md-3 text-center">
          <div class="col-lg-12 aos-init" data-aos="fade-in" data-aos-delay="" data-aos-duration="1000">
            <div class="feature-item">
              <h3 class="mp-highlight-title text-muted mb-0"><span data-aos="fade-in" data-aos-delay="750"
                  data-aos-duration="1000" class="aos-init">Tekniikka </span> <span data-aos="fade-in" data-aos-delay="1050"
                  data-aos-duration="1000" class="aos-init">ja </span> <span data-aos="fade-in" data-aos-delay="1350"
                  data-aos-duration="1000" class="aos-init">Design</span>
              </h3>
            </div>
          </div>
        </div>

        <div class="row mb-5 pb-5 mp-highlight">

          <div class="col-lg-3 my-auto d-none d-lg-block">
            <img src="assets/img/tekninen-mediapilvi.png" class="img-fluid" alt="Tekninen Mediapilvi">
          </div>

          <div class="col-lg-6">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12">
                  <div class="feature-item text-center">
                    <h3 class="mb-3 mp-highlight-title text-center"><span class="highlighting">Luovasti</span> yhdessä</h3>
                    <p class="mp-highlight-text text-muted">Haluamme luoda palveluita jotka vaikuttavat, kaikilla
                      mittareilla.</p>
                    <p class="mp-highlight-text text-muted">Onnistunut lopputulos voidaan saavuttaa vain hyvällä
                      idealla,
                      onko sinulla sellainen? Etsitään yhdessä ratkaisu.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 my-auto d-none d-lg-block">
            <img src="assets/img/luova-mediapilvi.png" class="img-fluid" alt="Luova Mediapilvi">
          </div>

        </div>

      </section>

    </div>
  </div>

</section>
