import { Component, OnInit , Inject} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { fadeAnimation } from '../../animations';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [fadeAnimation]
})
export class LayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onDeactivate(e) {
    window.scroll(0, 0);
  }

  onActivate(event) {
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

}
