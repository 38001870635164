import { Component, OnInit } from "@angular/core";
import * as AOS from "aos";
import { ModalService } from "./services/modal.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  modalOverlay!: HTMLElement;

  constructor(private modalService: ModalService) {}
  ngOnInit() {
    AOS.init();
    const htmlCode =
      "<img src='assets/img/kohina-logo.png'><p><b>Mediapilvi toimii jatkossa osana Kohina Creativea</b> - Mutta ei hätää! Muutos ei aiheuta teille minkäänlaisia toimenpiteitä ja teemme edelleen samoja asioita luovan suunnittelun ja kehityksen parissa.</p><p>Lähetä meille viestiä osoitteeseen info@mediapilvi.fi tai lue lisää Kohina Creativesta.</p><a href='https://kohinacreative.fi'>Lue lisää</a>";
    this.modalOverlay = this.modalService.openModal(htmlCode);

    this.modalOverlay.addEventListener("click", (event: MouseEvent) => {
      const isClickInsideModal =
        event.target instanceof HTMLElement &&
        event.target.closest(".custom-modal");
      if (!isClickInsideModal) {
        this.modalOverlay.remove();
      }
    });
  }
}
