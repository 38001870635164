import { Component, OnInit } from '@angular/core';
import { MetaService } from '../../services/meta.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  public siteTitle = 'Verkkopalveluita oikeisiin tarpeisiin';
  public metaTitle = 'Mediapilvi.fi - Verkkopalveluita oikeisiin tarpeisiin';
  public metaDesc = 'Mediapilvi on Helsingissä sijaitseva digitoimisto. Haluamme auttaa ihmisiä ja yrityksiä ymmärtämään ja ottamaan innokkaasti vastaan digitaalinen maailma.';
  public metaUrl = 'https://mediapilvi.fi';


  constructor(private meta: MetaService) {
    this.meta.updateTitle(this.metaTitle);
    this.meta.updateMetaInfo(this.metaTitle, this.metaDesc, 'https://mediapilvi.fi/assets/icon/mediapilvi-og-image.png');

  }

  ngOnInit() {

  }

}
