import { Component, OnInit } from '@angular/core';
import { MetaService } from '../../services/meta.service';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  public siteTitle = 'Tietoa';
  public metaTitle = 'Tietoa | Mediapilvi.fi';
  public metaDesc = 'Mediapilvi on Helsingissä sijaitseva digitoimisto. Haluamme auttaa ihmisiä ja yrityksiä ymmärtämään ja ottamaan innokkaasti vastaan digitaalinen maailma.';
  public metaUrl = 'https://mediapilvi.fi/tietoa';

  constructor(private meta: MetaService) {
    this.meta.updateTitle(this.siteTitle);
    this.meta.updateMetaInfo(this.metaTitle, this.metaDesc, 'https://mediapilvi.fi/assets/icon/mediapilvi-og-image.png');

  }

  ngOnInit() {

  }

}
