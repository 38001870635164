<div class="container pt-5 mb-5 pb-5">
  <div class="row">
    <div class="col-md-12">

      <section id="page-section pt-4">

        <div class="page-header">
          <h1><span class="text-muted d-block">Katso mitä</span> <span class="highlighting"> Mediapilvi</span> tekee</h1>
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <p>Helpoin tapa ymmärtää mitä teemme, on tutustua tekemiimme töihin. Katso alapuolelta case-esimerkkejä, aiemmin toteutuneista projektista.</p>
            </div>
          </div>

        </div>

        <div class="page-content" *ngIf="caseList">
          <div class="row p-1 m-1 p-md-3 m-md-3 pt-md-5 mp-highlight">
            <div class="col-lg-6" *ngFor="let case of caseList; let even = even; let odd = odd" [ngClass]="{ odd: odd, even: even }"
              data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000">
              <a routerLink="/caset/{{case?.slug}}" class="card-container mb-3 mt-3">
                <div class="card long-card" [ngStyle]="{'background-image': 'url(' + case?.coverImage + ')'}" style="background-size: cover;">
                  <div class="card-body">
                    <p>Case</p>
                    <div class="card-title">
                      {{ case?.title }}
                    </div>
                  </div>
                </div>
              </a>
            </div>
<!--             <div class="col-lg-6" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000">
                <div class="card long-card" [ngStyle]="" style="background: url('assets/img/coming-soon.png'); background-size: cover">
                  <div class="card-body">
                    <p>Case</p>
                    <div class="card-title">
                      Lisää tulossa heti joulun jälkeen 🎅
                    </div>
                  </div>
                </div>
            </div> -->
          </div>
        </div>

      </section>

    </div>
  </div>
</div>