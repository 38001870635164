import { Component, OnInit } from '@angular/core';
import { MetaService } from '../../services/meta.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public siteTitle = 'Ota yhteyttä | Mediapilvi';
  public metaTitle = 'Mediapilvi.fi - Verkkopalveluita oikeisiin tarpeisiin';
  public metaDesc = 'Ota yhteyttä meihin ja aletaan rakentamaan uutta!';
  public metaUrl = 'https://mediapilvi.fi/otayhteytta';


  constructor(private meta: MetaService) {
    this.meta.updateTitle(this.metaTitle);
    this.meta.updateMetaInfo(this.metaTitle, this.metaDesc, 'https://mediapilvi.fi/assets/icon/mediapilvi-og-image.png');

  }

  ngOnInit() {

  }

}
